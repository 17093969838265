<template>
  <div id="PreviousTransactions">
    <div class="panel">
      <v-row class="flex-row-reverse">
        <v-col cols="1" class="align-center">
          <v-btn @click="search" class="mt-4" icon>
            <v-icon color="primary">mdi-magnify</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Invoice"
            @keyup.enter="search()"
            append-icon="mdi-undo-variant"
            @click:append="
              invoiceNo = currentInvoiceNo;
              search();
            "
            clearable
            v-model.number="invoiceNo"
            id="invoiceNo"
            required="required"
            class="pb-1"
          />
        </v-col>
      </v-row>
      <missingImages :results="results" />
    </div>
  </div>
</template>
<script>
import missingImages from "@/components/nonPageComponents/missingImagesTable";
import { mapGetters } from "vuex";
export default {
  name: "MissingImages",
  data() {
    return {
      results: null,
      invoiceNo: this.$store.getters.invoiceNo
    };
  },
  components: {
    missingImages
  },
  methods: {
    async search() {
      this.$root.$emit("setLoading", true);
      this.results = await this.$api.missingDocuments(this.invoiceNo, {
        404: () => {}
      });

      this.$root.$emit("setLoading", false);
    }
  },
  async mounted() {
    this.search();
  },
  computed: {
    ...mapGetters({
      currentInvoiceNo: "invoiceNo"
    })
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/css/global-variables.scss";

::v-deep .b-table-sticky-header {
  max-height: calc(100vh - #{$nav-height} - #{$panel-margin}* 2 - 46px);
}

table {
  width: 100%;
}

tbody tr:hover {
  color: red;
}

.align-center {
  text-align: center;
}

#prev-transactions-header {
  display: flex;
  justify-content: flex-end;
}
</style>
